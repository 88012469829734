@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/bfd0b1fba96c4362-s.p.woff2) format('woff2');
font-display: block;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/bd07a594153dcb37-s.p.woff2) format('woff2');
font-display: block;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/77fe612261901d22-s.p.woff2) format('woff2');
font-display: block;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/201544f54242b6e3-s.p.woff2) format('woff2');
font-display: block;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/1169c18d939c1440-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/b2bdf2b7a6d26962-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/0c46c134f210ba00-s.p.woff2) format('woff2');
font-display: block;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/efde93533c1e7485-s.p.woff2) format('woff2');
font-display: block;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/1b944939c4c69f74-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/b30985a2d45d5a40-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/46e76d049eb67702-s.p.woff2) format('woff2');
font-display: block;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__ttBarrels_ba1fb0';
src: url(/_next/static/media/05ce4b07ca3edb55-s.p.woff2) format('woff2');
font-display: block;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__ttBarrels_Fallback_ba1fb0';src: local("Arial");ascent-override: 92.92%;descent-override: 35.74%;line-gap-override: 0.00%;size-adjust: 97.93%
}.__className_ba1fb0 {font-family: '__ttBarrels_ba1fb0', '__ttBarrels_Fallback_ba1fb0'
}.__variable_ba1fb0 {--font-tt-barrels: '__ttBarrels_ba1fb0', '__ttBarrels_Fallback_ba1fb0'
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/dff540113fff53f7-s.p.woff2) format('woff2');
font-display: block;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/78195e050b784f1f-s.p.woff2) format('woff2');
font-display: block;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/7dc6a11d1edfed82-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/e9c2086d440ff7ae-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/a15ac123f44a7216-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/18c5c895759b51f1-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/3650db37d46eea75-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/ecea20ec52a79e4f-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/fca0aa6caab5710d-s.p.woff2) format('woff2');
font-display: block;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/7816cf9958d19457-s.p.woff2) format('woff2');
font-display: block;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/f7ab84c48ce710b4-s.p.woff2) format('woff2');
font-display: block;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__maisonNeue_ae2711';
src: url(/_next/static/media/e5738d7d65d62012-s.p.woff2) format('woff2');
font-display: block;
font-weight: 800;
font-style: italic;
}@font-face {font-family: '__maisonNeue_Fallback_ae2711';src: local("Arial");ascent-override: 100.21%;descent-override: 29.22%;line-gap-override: 0.00%;size-adjust: 102.69%
}.__className_ae2711 {font-family: '__maisonNeue_ae2711', '__maisonNeue_Fallback_ae2711'
}.__variable_ae2711 {--font-maison-neue: '__maisonNeue_ae2711', '__maisonNeue_Fallback_ae2711'
}

@font-face {
font-family: '__maisonNeueMono_acd0ec';
src: url(/_next/static/media/e9cdb0e6efea007c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__maisonNeueMono_acd0ec';
src: url(/_next/static/media/0df407b2f4d5ed14-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__maisonNeueMono_acd0ec';
src: url(/_next/static/media/78a1c485c1a842ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__maisonNeueMono_acd0ec';
src: url(/_next/static/media/39aeb54d0d81172e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}@font-face {font-family: '__maisonNeueMono_Fallback_acd0ec';src: local("Arial");ascent-override: 71.04%;descent-override: 21.06%;line-gap-override: 0.00%;size-adjust: 142.45%
}.__className_acd0ec {font-family: '__maisonNeueMono_acd0ec', '__maisonNeueMono_Fallback_acd0ec'
}.__variable_acd0ec {--font-maison-neue-mono: '__maisonNeueMono_acd0ec', '__maisonNeueMono_Fallback_acd0ec'
}

